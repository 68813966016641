import React from "react";
import { Link } from "react-router-dom";
import "../styles/Jobs.css";

const Jobs = () => {
  const jobListings = [
    // {
    //     title: "Chef",
    //     location: "Flyover, Kenya - Along Nairobi-Naivasha Highway",
    //     description: "We are seeking an experienced Chef to manage our kitchen, plan menus, and prepare delicious meals with a focus on quality and presentation.",
    //     applyLink: "/contact"  // Link to application form or contact page
    // },
    {
        title: "Housekeeper",
        location: "Flyover, Kenya - Along Nairobi-Naivasha Highway",
        description: "Join our team as a Housekeeper to maintain cleanliness, ensure guest satisfaction, and manage household duties efficiently.",
        applyLink: "/contact"  // Link to application form or contact page
     },
    // {
    //     title: "Cleaner",
    //     location: "Flyover, Kenya - Along Nairobi-Naivasha Highway",
    //     description: "We are hiring a reliable Cleaner responsible for maintaining cleanliness and orderliness in various premises while ensuring health and safety standards.",
    //     applyLink: "/contact"  // Link to application form or contact page
    // }
];

  return (
    <div className="jobs-page">
      {/* Hero Section */}
      <section className="jobs-hero">
        <div className="hero-content">
          <h1>Join Our Team</h1>
          <p>Explore the exciting career opportunities we offer and become a part of our growing team.</p>
        </div>
      </section>

      {/* Job Listings Section */}
      <section className="job-listings">
        <h2>Current Job Openings</h2>

        {/* Conditionally render job listings or a "no openings" message */}
        {jobListings.length > 0 ? (
          <div className="job-grid">
            {jobListings.map((job, index) => (
              <div className="job-card" key={index}>
                <h3>{job.title}</h3>
                <p>{job.location}</p>
                <p>{job.description}</p>
                <Link to={job.applyLink} className="apply-button">
                  Apply Now
                </Link>
              </div>
            ))}
          </div>
        ) : (
          <p>No current job openings. Please check back later!</p>
        )}
      </section>

      {/* Why Work With Us Section */}
      <section className="why-work-with-us">
        <h2>Why Work With Us?</h2>
        <ul>
          <li>Competitive salary and benefits</li>
          <li>Opportunities for growth and advancement</li>
          <li>Collaborative and inclusive work environment</li>
          <li>Work-life balance with flexible schedules</li>
        </ul>
      </section>

      {/* How to Apply Section */}
      <section className="how-to-apply">
        <h2>How to Apply</h2>
        <p>If you are interested in any of the positions listed above, click the "Apply Now" button to get in touch with us.Send us your name, email address, and let us know the possition you want to apply to. Looking forward to hearing from you.</p>
      </section>
    </div>
  );
};

export default Jobs;
