import React, { useState, useEffect, useRef } from 'react';
import '../styles/Gallery.css'; // Assuming you have the CSS file for styling

const Gallery = () => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedCategory, setSelectedCategory] = useState("All");
  const [zoom, setZoom] = useState(1); // Zoom level state
  const imageRef = useRef(null);
  const [transformOrigin, setTransformOrigin] = useState("center center");
  const itemsPerPage = 12; // Display 6 items per page (2 rows of 3 cards each)

  const images = [
    { id: 1, src: 'https://www.dropbox.com/scl/fi/xi4217tb700u6qcers2b2/gso-main-1.jpeg?rlkey=sqqi15bvk7q98190a03kyrf3w&st=nyh1ga4c&raw=1', name: 'Front view', category: 'View' },
    { id: 2, src: 'https://www.dropbox.com/scl/fi/mpuryboq6t459tpg6rc7h/gso-entrance-1.jpg?rlkey=4ugfzow7ocrj7rm4oqmc9v9hv&st=7wvfkp4p&raw=1', name: 'Entrance', category: 'View' },
    { id: 3, src: 'https://www.dropbox.com/scl/fi/5ja6g4let8os8krmqmw2t/2023-11-01-1.jpg?rlkey=f5rw3zylq90by3hjkbv1p4wlw&st=9mwh6lyb&raw=1', name: 'Front View', category: 'View' },
    { id: 4, src: 'https://www.dropbox.com/scl/fi/845dbviyrs34z3jwq2ayq/2019-09-10.jpg?rlkey=tfx8u5tqd7yu2rnf82c11kvnw&st=09barufc&raw=1', name: 'Food', category: 'Food' },
    { id: 5, src: 'https://www.dropbox.com/scl/fi/xyoiwsrvwxc0t3rpvgfea/2019-09-10-1.jpg?rlkey=btftaixgn8vrb9gp0ow6qcg4d&st=t0qx1jh6&d&raw=1', name: 'Food', category: 'Food' },
    { id: 6, src: 'https://www.dropbox.com/scl/fi/uj9a4azpqkky2ay9581is/2019-09-10-2.jpg?rlkey=vc0ukzk52dwljif6q5g6ir0wz&st=274rdufw&raw=1', name: 'Food', category: 'Food' },
    { id: 7, src: 'https://www.dropbox.com/scl/fi/qhjclvynz23bhmjgbjzo8/Fj2ctcQXEAA9Gte.jpeg?rlkey=12rs685bn4kvup5q67womm9q1&st=r9lpcguc&raw=1', name: 'Conference Hall', category: 'Conference' },
    { id: 8, src: 'https://www.dropbox.com/scl/fi/zpn3rnsb5wkfglsd7kc66/FkWm6yzXwAAxki7-1.jpeg?rlkey=62d44n6kx2rxdqkq6918unt0x&st=yw3a9fy6&raw=1', name: 'Bedroom', category: 'Rooms' },
    { id: 9, src: 'https://www.dropbox.com/scl/fi/pz0brzzv205uhfjtwxci9/FjTJQcdX0AAlEmK.jpeg?rlkey=aaiw7wxf8s0pkct926y8e6dtz&st=cgklqyqe&raw=1', name: 'Bedroom', category: 'Rooms' },
    { id: 10, src: 'https://www.dropbox.com/scl/fi/rzolbdyk3h2g8ibf0ian2/FkWm6yvWYAIAoHL-1.jpeg?rlkey=dsfhmaonhrg3gr7h5b1qv3g7l&st=ffwhx0s6&raw=1', name: 'Conference Hall', category: 'Conference' },
    { id: 11, src: 'https://www.dropbox.com/scl/fi/uacsnth0yecfrifoz2p7o/2019-08-17.jpg?rlkey=stwzf1btlnrajlmsywyyrmjr1&st=c9e8d0yr&raw=1', name: 'Food', category: 'Food' },
    { id: 12, src: 'https://www.dropbox.com/scl/fi/way539pqlsnogn2tbeek8/2019-09-10-3.jpg?rlkey=rdnik6anihuwk4p3mcvm0g91o&st=wdgqel1g&raw=1', name: 'Food', category: 'Food' },
    { id: 13, src: 'https://www.dropbox.com/scl/fi/np7ed8yznp9mch0j0s1lj/IMG_20241108_142236_336.jpg?rlkey=00sabzglgrb8tjnq3combae9s&st=r8hzju4l&raw=1', name: 'Mt. Longonot View', category: 'View' },
    { id: 14, src: 'https://www.dropbox.com/scl/fi/a1qim2rzmf4v3cjixyo8e/IMG_20240821_164405_320.jpg?rlkey=iocdtihtc1x6joi6c7fqqpp10&st=b9s2mym7&raw=1', name: 'Food', category: 'Food' },
    { id: 15, src: 'https://www.dropbox.com/scl/fi/6db8vv37461s1trop1z2v/IMG_20241108_142933_933.jpg?rlkey=eiat6uvj1q8jqpfozi2kzyzr3&st=y24958qs&raw=1', name: 'Lake Naivasha View', category: 'View' },
    { id: 16, src: 'https://www.dropbox.com/scl/fi/khf3xxglunixs4lpgcpz0/IMG_20241108_150213_613.jpg?rlkey=gg7n08iipnljwk81vci3wz8ae&st=rqgfe9ye&raw=1', name: 'Bedroom', category: 'Rooms' },
    { id: 17, src: 'https://www.dropbox.com/scl/fi/xh2icup2jc5p1te5d5mki/IMG_20241108_150241_512.jpg?rlkey=mjjivg200j8ekvq3x5eudypas&st=mjynpm1g&raw=1', name: 'Bedroom', category: 'Rooms' },
    { id: 18, src: 'https://www.dropbox.com/scl/fi/g16zqvnoa69oeywjra7ie/IMG_20241108_150418_720.jpg?rlkey=el2fkiylgvx2pykizarzzd3ua&st=rnjy2n0i&raw=1', name: 'Room', category: 'Rooms' },
    { id: 19, src: 'https://www.dropbox.com/scl/fi/aug0bttggt8q50zx30oti/IMG_20241017_183539_649.jpg?rlkey=mgxwbtzdmfsgyth1lltt4dfqk&st=cwui3s8r&raw=1', name: 'Food', category: 'Food' },
    { id: 20, src: 'https://www.dropbox.com/scl/fi/5h8p9pfxdyvdlsaxvsoen/IMG_20241108_161029_415.jpg?rlkey=r0sva8158yjv9u352wj9i1u3s&st=2qkg78z5&raw=1', name: 'Lounge', category: 'Rooms' },
    { id: 21, src: 'https://www.dropbox.com/scl/fi/o7k4za0ije7yg1b7gxso4/IMG_20241108_143448_921.jpg?rlkey=5yrbpoqboyxaisvrbx721lh9t&st=squz0dkj&raw=1', name: 'Conference Hall', category: 'Conference' },
    { id: 22, src: 'https://www.dropbox.com/scl/fi/yw4j6jq9gnolz8ve3hejp/IMG_20241001_114812_952.jpg?rlkey=4214cja4qaiuq617vagqfgbu1&st=mpsz3qpg&raw=1', name: 'Parking Aerial View', category: 'View' },
    { id: 23, src: 'https://www.dropbox.com/scl/fi/n8xmw28ayoduiv6g7k3xp/IMG_20241108_155322_577.jpg?rlkey=4pweqa0n5asvbol1gxg6etim4&st=332pn6n1&raw=1', name: 'Field', category: 'View' },
    { id: 24, src: 'https://www.dropbox.com/scl/fi/lc6gpxfuoc2cd6c64q7h1/IMG_20241108_153600_592.jpg?rlkey=34uklvebpx57fp4wvzbfp8k7h&st=f27vpmjq&raw=1', name: 'Lounge', category: 'Rooms' },
    { id: 25, src: 'https://www.dropbox.com/scl/fi/ktqy6ffv719uotdihlyvz/IMG_20241108_160802_144.jpg?rlkey=2m5p6z422zcvintzuifkmykzx&st=fw74oa9k&raw=1', name: 'Lounge', category: 'Rooms' },
    { id: 26, src: 'https://www.dropbox.com/scl/fi/70y7l27eap55na489v7sq/IMG_20241108_161143_097.jpg?rlkey=sbpzlo5m9cjid9itdkchvcbaf&st=yvpu03a5&raw=1', name: 'Lounge', category: 'Rooms' },
    { id: 27, src: 'https://www.dropbox.com/scl/fi/dwyr044e5lhwnca6ogox5/IMG_20241108_155444_823.jpg?rlkey=dce5cp7mgzpkgii5s5m7hwow3&st=px09mxv9&raw=1', name: 'Field', category: 'View' },  // Add more images as needed
    { id: 28, src: 'https://www.dropbox.com/scl/fi/lq9yxu917wb6xq3iuv2qs/IMG_20241108_150847_716.jpg?rlkey=uoqar687kcf3cf8qywgd3xtbl&st=r1uoyt1h&raw=1', name: 'Room', category: 'Rooms' },
    { id: 29, src: 'https://www.dropbox.com/scl/fi/zd694rqg1821alhkukhe8/IMG_20241108_152618_743.jpg?rlkey=l3jozybs4vtxyj5i6x3asar7i&st=z2d3n9sz&raw=1', name: 'Room', category: 'Rooms' },
    { id: 30, src: 'https://www.dropbox.com/scl/fi/wxx8jwgvcm6ac9vbfnmjd/IMG_20241108_151527_016.jpg?rlkey=v8gnz88bw8hvy8i8ddfjp7o4r&st=9hd5lfdl&raw=1', name: 'Room', category: 'Rooms' },
    { id: 31, src: 'https://www.dropbox.com/scl/fi/ulfj8ts71m2umqvnmbbt2/20190730_095647-1.jpg?rlkey=xwz89b7ubvp7s6qcf65tjox29&st=v58ofy7g&raw=1', name: 'Restaurant', category: 'Restaurant' },
    { id: 32, src: 'https://www.dropbox.com/scl/fi/39wer9cxsj4jys324syqx/20190527_193740.jpg?rlkey=2g5mgqfk98646qc6a7kqav7ct&st=avk1yb1v&raw=1', name: 'Restaurant', category: 'Restaurant' },
    { id: 33, src: 'https://www.dropbox.com/scl/fi/cs6alze4tph7aq3szn9wr/20190526_213209.jpg?rlkey=94yokjzdlmm87raih28l1nnj4&st=m90h1zaz&raw=1', name: 'Restaurant', category: 'Restaurant' },
    { id: 34, src: 'https://www.dropbox.com/scl/fi/o4o3cm6g0rcx9n44zsnsc/IMG_20241121_124235_476.jpg?rlkey=64fszzqy3xlvrihyyi6sper7y&st=fygrz9hq&raw=1', name: 'Conference', category: 'Conference' },
    { id: 35, src: 'https://www.dropbox.com/scl/fi/oj1mesmuwen1rjq4rfvro/IMG_20241121_131909_226.jpg?rlkey=m7e347ugregs5qctjb4xs5yt5&st=114i06tt&raw=1', name: 'Conference', category: 'Conference' },
    { id: 36, src: 'https://www.dropbox.com/scl/fi/yzelqhxksmjni3u62mtq8/IMG_20241121_135838_139.jpg?rlkey=l6ixhcx42jdig4h8oar52s5jj&st=pdhjv2my&raw=1', name: 'Conference', category: 'Conference' },
    { id: 37, src: 'https://www.dropbox.com/scl/fi/yzelqhxksmjni3u62mtq8/IMG_20241121_135838_139.jpg?rlkey=l6ixhcx42jdig4h8oar52s5jj&st=mdzskq82&raw=1', name: 'Conference', category: 'Conference' },
    { id: 38, src: 'https://www.dropbox.com/scl/fi/nce3x75xo7dyvtlb6jo58/20190527_193736.jpg?rlkey=xx10aiujdozhkbf4lic0uls8h&st=8htv2hur&raw=1', name: 'Restaurant', category: 'Restaurant' },
    { id: 39, src: 'https://www.dropbox.com/scl/fi/i67aq6zhxpb2zedm30cps/IMG-20241122-WA0005.jpg?rlkey=76qouytnowtat9qpc2ll10xwy&st=tfait614&raw=1', name: 'Restaurant', category: 'Restaurant' },
    { id: 40, src: 'https://www.dropbox.com/scl/fi/q83vbgyv7o9ikb8fcd06m/IMG-20241122-WA0006.jpg?rlkey=0l2d2i3y2ymoogos8237anpnh&st=lznv2y0u&raw=1', name: 'View', category: 'View' },
    { id: 41, src: 'https://www.dropbox.com/scl/fi/ybnje8zepdvffnxcyvvak/IMG-20241122-WA0007.jpg?rlkey=ogv62odiumktd5i58p8unexp5&st=aamqlonz&raw=1', name: 'View', category: 'View' },
    { id: 42, src: 'https://www.dropbox.com/scl/fi/xehfo1tub8iicn1tsiv3w/IMG-20241122-WA0008.jpg?rlkey=28eus7iqe4pews0ocut0q6yd5&st=0w1cwvgv&raw=1', name: 'View', category: 'View' },
    { id: 43, src: 'https://www.dropbox.com/scl/fi/3ht718cipqqoq2bl1tdy3/IMG-20241122-WA0009.jpg?rlkey=lsgus0hezf763lijdrtoa2dzt&st=fg5ou8t0&raw=1', name: 'View', category: 'View' },
    { id: 44, src: 'https://www.dropbox.com/scl/fi/ilvfhj1ohfp2shasul5ae/IMG-20241122-WA0011.jpg?rlkey=r4zn67fd5bsnwyal665q4kg9h&st=9axmcbhg&raw=1', name: 'View', category: 'View' },
    { id: 45, src: 'https://www.dropbox.com/scl/fi/5jz9kc0wezvltxw2crtik/IMG-20241122-WA0016.jpg?rlkey=fqqmuj18xgeuxbht4qe8cdtk9&st=i7rdidx8&raw=1', name: 'Food', category: 'Food' },
    { id: 46, src: 'https://www.dropbox.com/scl/fi/kplqvuni1d5ztg3qx8b9n/IMG-20241122-WA0015.jpg?rlkey=k701sdgb83icmzbav9avz0zl8&st=f4mkwzuu&raw=1', name: 'View', category: 'View' },
    { id: 47, src: 'https://www.dropbox.com/scl/fi/ug6jpd8q8k7ske6uwj2bd/IMG-20241122-WA0012.jpg?rlkey=4lue1n59ciejxxi99j4fjpumo&st=7npdxj1f&raw=1', name: 'Restaurant', category: 'Restaurant' },
    { id: 48, src: 'https://www.dropbox.com/scl/fi/i4a3be6rw4mwgljpxya1l/IMG-20241122-WA0010.jpg?rlkey=lygzfu2ni53oon60ej1plbx3m&st=slu3nfyx&raw=1', name: 'Restaurant', category: 'Restaurant' },
    { id: 49, src: 'https://www.dropbox.com/scl/fi/a3clua453an4efsojfppc/IMG_20241126_151232_584.jpg?rlkey=8tcoekuc00assyfhh3xtwuurh&st=ijid88t6&raw=1', name: 'Parking', category: 'View' },
    { id: 50, src: 'https://www.dropbox.com/scl/fi/2p3mwbkvxw11b66gtko3d/IMG_20241126_152220_790.jpg?rlkey=mf1vlcbf8nhtatzm3hvsceylz&st=wqzv5ap3&raw=1', name: 'Parking', category: 'View' },
    { id: 51, src: 'https://www.dropbox.com/scl/fi/qy7j37x6cmle4rnapaagc/20250128_150757.jpg?rlkey=rej5826sttwca8qwj0glz69lw&st=o6u5yufw&raw=1', name: 'Conference', category: 'conference' },
    { id: 52, src: 'https://www.dropbox.com/scl/fi/w3ktbwn8nnarqfh1z3p21/20250128_150649.jpg?rlkey=qae5gtox1bpalgowdm7y5em5q&st=o6yymr7j&raw=1', name: 'Conference', category: 'conference' },
  ];

  const categories = ['All', 'Restaurant', 'Food', 'Rooms', 'Conference', 'View'];

  const filteredImages =
    selectedCategory === 'All'
      ? images
      : images.filter((image) => image.category === selectedCategory);

  const totalPages = Math.ceil(filteredImages.length / itemsPerPage);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [currentPage]);

  const handlePageChange = (page) => {
    if (page < 1 || page > totalPages) return;
    setCurrentPage(page);
  }; 
    
  const generatePageNumbers = (totalPages, currentPage) => {
    const pageNumbers = [];
    const maxPagesToShow = 5; // Adjust how many pages to show around the current page

    if (totalPages <= maxPagesToShow) {
      // Show all pages if the total number is less than or equal to maxPagesToShow
      for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
      }
    } else {
      if (currentPage > 3) {
        pageNumbers.push(1); // Always show the first page
        if (currentPage > 4) {
          pageNumbers.push('...'); // Add ellipsis if there's a gap
        }
      }

      // Show current page and two pages before and after it
      for (
        let i = Math.max(1, currentPage - 2);
        i <= Math.min(totalPages, currentPage + 2);
        i++
      ) {
        pageNumbers.push(i);
      }

      if (currentPage < totalPages - 2) {
        if (currentPage < totalPages - 3) {
          pageNumbers.push('...');
        }
        pageNumbers.push(totalPages); // Always show the last page
      }
    }

    return pageNumbers;
  };

  const handlePageClick = (page) => {
    if (page === '...') return; // Do nothing for ellipsis
    handlePageChange(page);
  };
    

  const openImage = (image) => {
    setSelectedImage(image);
    setZoom(1); // Reset zoom when opening a new image
    setTransformOrigin("center center"); // Reset transform-origin to center
  };

  const closeImage = () => {
    setSelectedImage(null);
  };

  const handleCategoryChange = (category) => {
    setSelectedCategory(category);
    setCurrentPage(1); // Reset to first page when category changes
  };

  const currentImages = filteredImages.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const showPreviousImage = () => {
    const prevIndex = (currentIndex - 1 + images.length) % images.length;
    setSelectedImage(images[prevIndex]);
    setCurrentIndex(prevIndex);
    setZoom(1); // Reset zoom when switching images
    setTransformOrigin("center center"); // Reset transform-origin when changing image
  };

  // Function to show next image
  const showNextImage = () => {
    const nextIndex = (currentIndex + 1) % images.length;
    setSelectedImage(images[nextIndex]);
    setCurrentIndex(nextIndex);
    setZoom(1); // Reset zoom when switching images
    setTransformOrigin("center center"); // Reset transform-origin when changing image
  };

  const handleWheel = (e) => {
    if (!imageRef.current) return;

    // Get mouse position relative to the image
    const imageRect = imageRef.current.getBoundingClientRect();
    const mouseX = e.clientX - imageRect.left;
    const mouseY = e.clientY - imageRect.top;

    // Calculate the new transform-origin (where the zoom will focus)
    const newTransformOrigin = `${(mouseX / imageRect.width) * 100}% ${(mouseY / imageRect.height) * 100}%`;

    // Zoom in on scroll up, zoom out on scroll down
    const newZoom = zoom + e.deltaY * -0.01; // Adjust the factor to control zoom sensitivity
    if (newZoom >= 1 && newZoom <= 5) { // Set limits for zoom level (e.g., min 1x, max 3x)
      setZoom(newZoom);
      setTransformOrigin(newTransformOrigin); // Update transform-origin for zoom focus
    }
  };
  

  return (
    <div className="gallery-container">
      <div className="gallery-filters">
        {categories.map((category) => (
          <button
            key={category}
            onClick={() => handleCategoryChange(category)}
            className={`filter-btn ${selectedCategory === category ? 'active' : ''}`}
          >
            {category}
          </button>
        ))}
      </div>

      <div className="gallery">
        {currentImages.map((image) => (
          <div className="card" key={image.id} onClick={() => openImage(image)}>
            <img src={image.src} alt={image.name} className="card-image" loading="lazy"/>
            <div className="card-name">{image.name}</div>
          </div>
        ))}
      </div>

      {selectedImage && (
        <div className="overlay" onClick={closeImage}>
          <div className="full-view" onClick={(e) => e.stopPropagation()} onWheel={handleWheel}>
            <img
              ref={imageRef}
              src={selectedImage.src}
              alt={selectedImage.name}
              className="full-view-image"
              style={{
                transform: `scale(${zoom})`, // Apply zoom scale
                transformOrigin: transformOrigin, // Set the zoom origin based on the cursor position
              }}
            />
            <button className="close-btn" onClick={closeImage}>
              <i className="fas fa-times"></i>
            </button>
            <i
              className="fas fa-chevron-left prev-icon"
              onClick={showPreviousImage}
            ></i>
            <i
              className="fas fa-chevron-right next-icon"
              onClick={showNextImage}
            ></i>
          </div>
        </div>
      )}
        <div className="pagination">
          <button onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1}>
            Prev
          </button>
          {generatePageNumbers(totalPages, currentPage).map((page, index) => (
            <button
              key={index}
              onClick={() => handlePageClick(page)}
              disabled={page === currentPage || page === '...'}
              style={{
                fontWeight: page === currentPage ? 'bold' : 'normal',
                cursor: page === '...' ? 'default' : 'pointer',
              }}
            >
              {page}
            </button>
          ))}
          <button onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages}>
            Next
          </button>
        </div>
    </div>
  );
};

export default Gallery;